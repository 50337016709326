
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUploadList from "@/components/scope/file-upload-list.vue";
import Template from "@/views/dashboard/template.vue";

const app = namespace("app");
const base = namespace("base");
const wxcpSetTagTask = namespace("wxcpSetTagTask");

@Component({
  components: {Template, FileUpload, Editor, FileUploadList }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @app.Mutation setPBPX;
  @wxcpSetTagTask.Action taskDetail;

  emptyToLine = emptyToLine;
  dayjs = dayjs;

  get breadData() {
    return [
      { name: "企微用户打标", path: "/wxcpSetTagTask/list" },
      {
        name: "任务详情"
      }
    ];
  }
  taskTypeList: any = [
    {
      label: "新手任务",
      value: 1
    },
    {
      label: "日常任务",
      value: 2
    }
  ];
  conditionTypeList: any = [
    // {
    //   label: "固定城市",
    //   value: 1
    // },
    {
      label: "固定用户",
      value: 2
    },
    {
      label: "新老用户",
      value: 3
    }
  ]

  created() {
    this.init();
  }
  get taskId() {
    return this.$route.query.taskId;
  }

  taskDetailInfo: any = {};

  init() {
    // 获取详情
    if(this.taskId){
      this.taskDetail({taskId: this.taskId}).then(res => {
        this.taskDetailInfo = res.data;
      })
    }
  }
  mounted() {}
}
